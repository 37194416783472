import { getAuthorizationHeaderWithContentType } from "../../helpers/utils";
import { clearLoginData } from "../../helpers/store";
import { toast } from "react-toastify";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  addDoc,
} from "firebase/firestore";
import app from "../../firebase";

const fireStoreDB = getFirestore(app);

async function fetchCategories() {
  const q = query(collection(fireStoreDB, "EmissionsFactors"));
  const querySnapshot = await getDocs(q);

  let primaryCategories = [];
  let combinedCategoryFactorsObj = {};

  querySnapshot.forEach((doc) => {
    primaryCategories.push(doc.id);
    combinedCategoryFactorsObj[doc.id] = doc.data();
  });
  // console.log("combinedCategoryFactorsObj", combinedCategoryFactorsObj);
  // console.log("primaryCategories", primaryCategories);
  // setPrimaryCategories(primaryCategories);
  // setCombinedCategoryFactorsObj(combinedCategoryFactorsObj);

  return combinedCategoryFactorsObj;
}

export const saveOnBoardingDetails = async (body) => {
  try {
    const response = await fetch(
      "/api/users/update-business-onboarding-details",
      {
        method: "POST",
        headers: getAuthorizationHeaderWithContentType(),
        body: JSON.stringify(body),
        mode: "cors",
      }
    );

    if (response.status === 401) {
      clearLoginData();
    } else if (response.status !== 200) {
      toast.error("Failed to save onboarding details.");
    } else {
      toast.success(`Successfully saved.`);
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateLocation = async (body) => {
  try {
    const response = await fetch(`/api/users/update-office`, {
      method: "PUT",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 200) {
      return response;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const updateSuppliersCollection = async (body) => {
  try {
    const categories = await fetchCategories();
    // Initialize your JSON object with empty strings and zeros
    const {
      businessId,
      businessName,
      country,
      state,
      primaryCategory,
      secondaryCategory,
    } = body;

    const primaryEmissionFactor =
      categories[primaryCategory]["average_emission_factor"];
    const secondaryEmissionFactor =
      categories[primaryCategory][secondaryCategory];

    // console.log("categories", categories);
    // console.log("body", body);

    const jsonObject = {
      abn: "",
      addresses: [
        {
          addressType: "STREET",
          city: "",
          country: country,
          postalCode: "",
          region: state,
          street: "",
        },
        {
          addressType: "POBOX",
          city: "",
          country: "",
          postalCode: "",
          region: "",
          street: "",
        },
      ],
      businessIntesityFactor: 0,
      connectedCompaniesId: [""],
      country: "",
      email: "",
      isSupplier: true,
      merchantLogo: "",
      name: businessName,
      netnadaId: businessId,
      phones: [
        {
          phoneAreaCode: "",
          phoneCountryCode: "",
          phoneNumber: "",
          phoneType: "DDI",
        },
        {
          phoneAreaCode: "",
          phoneCountryCode: "",
          phoneNumber: "",
          phoneType: "DEFAULT",
        },
        {
          phoneAreaCode: "",
          phoneCountryCode: "",
          phoneNumber: "",
          phoneType: "FAX",
        },
        {
          phoneAreaCode: "",
          phoneCountryCode: "",
          phoneNumber: "",
          phoneType: "MOBILE",
        },
      ],
      primaryCategory: primaryCategory,
      primaryEmissionFactor: primaryEmissionFactor,
      secondaryCategory: secondaryCategory,
      secondaryEmissionFactor: secondaryEmissionFactor,
      status: "approved",
      website: "",
      yearlyBusinessEmissions: 0,
    };

    const docRef = await addDoc(
      collection(fireStoreDB, "Suppliers"),
      jsonObject
    );
    console.log("Supplier Created with ID: ", docRef.id);
  } catch (error) {
    console.error(error);
  }
};
