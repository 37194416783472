import {
  faLeaf,
  faChartBar,
  faUsers,
  faBuilding,
  faNewspaper,
  faBatteryHalf,
  faRecycle,
  faTruck,
  faGlobe,
  faFileAlt,
  faClipboardCheck,
  faCompass,
  faBriefcase,
  faUser,
  faBed,
  faHammer,
  faBookOpen,
  faDollarSign,
  faUtensils,
  faHeart,
  faDesktop,
  faGavel,
  faIndustry,
  faHome,
  faShoppingCart,
  faPlane,
  faBatteryFull,
  faPlusCircle,
  faFlask,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import XeroLogo from "../Assets/onboardingAssets/xero-logo.jpg";
import QuickbooksLogo from "../Assets/onboardingAssets/QB_logo.png";
import MYOBLogo from "../Assets/onboardingAssets/myob-logo.jpg";
import NetSuiteLogo from "../Assets/onboardingAssets/net-suite-logo.webp";
import SAPLogo from "../Assets/onboardingAssets/SAP_logo.png";
import SageLogo from "../Assets/onboardingAssets/Sage_logo.webp";

export const questionsData = [
  {
    id: 1,
    question:
      "What is your experience with business sustainability? (Select all that apply)",
    options: [
      {
        label:
          "Carbon Accounting \u2013 Measuring and tracking carbon emissions within my business.",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faChartBar} size="lg" />,
      },
      {
        label:
          "Energy Efficiency \u2013 Implementing energy-saving technologies and processes.",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faBatteryHalf} size="lg" />,
      },
      {
        label:
          "Waste Reduction \u2013 Reducing, reusing, and recycling materials in business operations.",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faRecycle} size="lg" />,
      },
      {
        label:
          "Sustainable Supply Chain \u2013 Ensuring sustainability practices across suppliers and partners.",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faTruck} size="lg" />,
      },
      {
        label:
          "Circular Economy \u2013 Designing products or services with reusability and minimal waste in mind.",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faLeaf} size="lg" />,
      },
      {
        label:
          "Sustainable Development Goals (SDGs) \u2013 Aligning business operations with the United Nations SDGs.",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faGlobe} size="lg" />,
      },
      {
        label:
          "Climate Risk Management \u2013 Identifying and mitigating business risks related to climate change.",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faCompass} size="lg" />,
      },
      {
        label:
          "Sustainable Product Design \u2013 Developing products that minimize environmental impact.",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faLeaf} size="lg" />,
      },
      {
        label:
          "Employee Engagement in Sustainability \u2013 Involving staff in sustainability practices and initiatives.",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faUsers} size="lg" />,
      },
      {
        label:
          "Sustainability Reporting \u2013 Publicly sharing sustainability efforts and progress through reports or disclosures.",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faClipboardCheck} size="lg" />,
      },
      {
        label:
          "No Focus Yet \u2013 I am interested in learning about different sustainability areas to start.",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faFileAlt} size="lg" />,
      },
    ],
    type: "SelectBox",
    description:
      "We will use this to show you relavant tips, guides, and courses in the NetNada software.",
    previousQuestion: null,
    nextQuestion: 2,
    skip: false,
  },
  {
    id: 2,
    question: "Who are you helping with your sustainability efforts?",
    options: [
      {
        label: "The company I work for",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faBuilding} size="lg" />,
      },
      {
        label: "Clients (as a consultant)",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faBriefcase} size="lg" />,
      },
    ],
    type: "SelectBox",
    description: "Access the right features for a smooth measurement.",
    nextQuestion: 3,
    previousQuestion: 1,
    skip: false,
  },
  {
    id: 3,
    question: "What's the name of the company?",
    options: [
      {
        label: "Company Name",
        color: "#DCFCE7",
      },
    ],
    type: "TextBox",
    description: "We will use this to personalize your experience.",
    previousQuestion: 2,
    nextQuestion: 4,
    skip: false,
  },
  {
    id: 4,
    question: "Company headcount",
    options: [
      {
        label: "1-10",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faUser} size="lg" />,
      },
      {
        label: "11-50",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faUsers} size="lg" />,
      },
      {
        label: "51-100",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faUsers} size="lg" />,
      },
      {
        label: "101-250",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faUsers} size="lg" />,
      },
      {
        label: "251-499",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faUsers} size="lg" />,
      },
      {
        label: "500+",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faUsers} size="lg" />,
      },
    ],
    type: "SelectBox",
    description: "Select the range that best fits your company size.",
    nextQuestion: 5,
    previousQuestion: 3,
    skip: false,
  },
  {
    id: 5,
    question: "Industry",
    options: [
      {
        label: "Accommodation and Tourism",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faBed} size="lg" />, // Represents hospitality and accommodation
      },
      {
        label: "Architecture",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faBuilding} size="lg" />, // Represents buildings and design
      },
      {
        label: "Construction",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faHammer} size="lg" />, // Represents construction tools
      },
      {
        label: "Consulting",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faBriefcase} size="lg" />, // Represents consulting and professional services
      },
      {
        label: "Education & Training",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faBookOpen} size="lg" />, // Represents books and learning
      },
      {
        label: "Environmental Services",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faLeaf} size="lg" />, // Represents environmental focus
      },
      {
        label: "Financial Services",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faDollarSign} size="lg" />, // Represents finance and money
      },
      {
        label: "Food",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faUtensils} size="lg" />, // Represents food and dining
      },
      {
        label: "Healthcare",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faHeart} size="lg" />, // Represents healthcare and medical services
      },
      {
        label: "Information Technology",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faDesktop} size="lg" />, // Represents technology and computing
      },
      {
        label: "Legal Services",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faGavel} size="lg" />, // Represents legal services and law
      },
      {
        label: "Manufacturing",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faIndustry} size="lg" />, // Represents factories and production
      },
      {
        label: "Mining",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faTruck} size="lg" />, // Represents mining and heavy industries
      },
      {
        label: "Non-Profit Organisations",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faUsers} size="lg" />, // Represents community and non-profits
      },
      {
        label: "Professional, Scientific & Technical Services",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faFlask} size="lg" />, // Represents scientific research and professional services
      },
      {
        label: "Property & Real Estate",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faHome} size="lg" />, // Represents real estate and properties
      },
      {
        label: "Public Administration",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faGlobe} size="lg" />, // Represents government and public administration
      },
      {
        label: "Retail & Wholesale Trade",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faShoppingCart} size="lg" />, // Represents retail and trade
      },
      {
        label: "Transport & Logistics",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faPlane} size="lg" />, // Represents transportation and logistics
      },
      {
        label: "Utilities, Energy & Waste Services",
        color: "#DCFCE7",
        icon: <FontAwesomeIcon icon={faBatteryFull} size="lg" />, // Represents energy and utility services
      },
    ],
    type: "SelectBox",
    description: "Select the industry that best describes your company.",
    nextQuestion: 6,
    previousQuestion: 4,
    skip: false,
  },
  {
    id: 6,
    question: "Please select the accounting software you use",
    options: [
      {
        label: "MYOB",
        logo: MYOBLogo,
        color: "#AD028C",
        size: "100px",
      },
      {
        label: "NetSuite",
        logo: NetSuiteLogo,
        color: "#12527C",
        size: "100px",
      },
      {
        label: "Quickbooks",
        logo: QuickbooksLogo,
        color: "#27A037",
        size: "100px",
      },
      {
        label: "Sage",
        logo: SageLogo,
        color: "#00DD00",
        size: "100px",
      },
      {
        label: "SAP",
        logo: SAPLogo,
        color: "#0A6E9E",
        size: "120px",
      },
      {
        label: "Xero",
        logo: XeroLogo,
        color: "#00B8E3",
        size: "150px",
      },
      {
        label: "Other",
        icon: <FontAwesomeIcon icon={faPlusCircle} size="lg" />,
        color: "#DCFCE7",
      },
    ],
    type: "IntegrationSelectBox",
    description: "Connect your accounting software to automate data entry.",
    nextQuestion: 7,
    previousQuestion: 5,
    skip: false,
  },
  {
    id: 7,
    question: "Why do you need a carbon measurement? Select all that apply",
    options: [
      {
        label: "It was requested by a current client",
        icon: <FontAwesomeIcon icon={faUsers} size="lg" />,
        color: "#FED7A7",
        nextQuestion: 8,
      },
      {
        label: "To be included in a tender or proposal",
        icon: <FontAwesomeIcon icon={faUsers} size="lg" />,
        color: "#FED7A7",
        nextQuestion: 9,
      },
      {
        label: "As part of a certification or reporting requirement",
        icon: <FontAwesomeIcon icon={faUsers} size="lg" />,
        color: "#FED7A7",
        nextQuestion: 10,
      },
      {
        label: "As part of company strategy and value alignment",
        icon: <FontAwesomeIcon icon={faUsers} size="lg" />,
        color: "#FED7A7",
        nextQuestion: null,
      }, // No more questions after this
    ],
    type: "MultiSelectDecisionBox",
    description:
      "Streamline your data collection, approval and response processes.",
    previousQuestion: 6,
    skip: false,
  },
  {
    id: 8,
    question:
      "Enter the details of the company that is requesting this information",
    options: [{ label: "Company Name" }, { label: "Website" }],
    type: "TextBox",
    description: "We can assist with prefilling data on your behalf.",
    nextQuestion: null, // Final question
    previousQuestion: 7,
    skip: true, // Optionally, skip this question based on previous answers
  },
  {
    id: 9,
    question: "Enter the details of the company that you are tendering for",
    options: [{ label: "Tender Name" }, { label: "Website" }],
    type: "TextBox",
    description: "We can assist with prefilling data on your behalf.",
    nextQuestion: null, // Final question
    previousQuestion: 7,
    skip: true, // Optionally, skip this question based on previous answers
  },
  {
    id: 10,
    question: "Please select all that apply",
    options: [
      {
        label: "UN Global Compact and SDGs",
        icon: <FontAwesomeIcon icon={faNewspaper} size={30} />,
        color: "#FED7A7",
      },
      {
        label: "CDP Reporting",
        icon: <FontAwesomeIcon icon={faNewspaper} size={30} />,
        color: "#FED7A7",
      },
      {
        label: "TCFD",
        icon: <FontAwesomeIcon icon={faNewspaper} size={30} />,
        color: "#FED7A7",
      },
      {
        label: "ISSB",
        icon: <FontAwesomeIcon icon={faNewspaper} size={30} />,
        color: "#FED7A7",
      },
      {
        label: "Ecovadis",
        icon: <FontAwesomeIcon icon={faNewspaper} size={30} />,
        color: "#FED7A7",
      },
      {
        label: "Carbon neutrality",
        icon: <FontAwesomeIcon icon={faNewspaper} size={30} />,
        color: "#FED7A7",
      },
      {
        label: "Standalone report (for internal use)",
        icon: <FontAwesomeIcon icon={faNewspaper} size={30} />,
        color: "#FED7A7",
      },
      {
        label: "B-Corp",
        icon: <FontAwesomeIcon icon={faNewspaper} size={30} />,
        color: "#FED7A7",
      },
      {
        label: "Other",
        icon: <FontAwesomeIcon icon={faNewspaper} size={30} />,
        color: "#FED7A7",
      },
    ],
    type: "MultiSelectBox",
    description:
      "Our expert team will ensure you are ready for any 3rd party submissions.",
    nextQuestion: null, // Final question
    previousQuestion: 7,
    skip: false,
  },
];
