import React from "react";
import OnBoardingCardLayout from "../../layouts/OnBoardingCardLayout";
import BadgeDemo from "./BadgeDemo";

const GettingStarted = () => {
  return (
    <OnBoardingCardLayout
      leftSideContent={
        <div>
          <h1 style={{ color: "#005677" }}>Getting Started</h1>
          <p style={{ color: "#333333", fontSize: "18px" }}>
            Welcome to your journey of carbon accounting! We’re excited to help
            you understand your environmental impact and guide you to a more
            sustainable future. Let’s begin by exploring the key features of our
            platform.
          </p>
          <ul style={{ color: "#333333", fontSize: "16px" }}>
            <li>Measure and track your emissions.</li>
            <li>Get insights on your data.</li>
            <li>Communicate your progress effectively.</li>
            <li>Grow with sustainability initiatives.</li>
          </ul>
        </div>
      }
      showGettingStarted={true}
      showQuestions={false}
    >
      {/* Right side content - Image */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%", // Ensure the image takes the full height of the column
        }}
      >
        <BadgeDemo />
      </div>
    </OnBoardingCardLayout>
  );
};

export default GettingStarted;
