import React from "react";
import { Card } from "react-bootstrap";
import BadgeGlobe from "../../Assets/onboardingAssets/BadgeGlobe.png";

const ClimateChampionBadge = ({ name }) => {
  return (
    <Card
      style={{
        width: "350px", // Slightly wider to match your design
        height: "500px", // Adjust height to fit elements well
        background: "linear-gradient(to bottom right, #ebf8ff, #ffe5d8)",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRadius: "15px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Add subtle shadow for depth
      }}
    >
      <Card.Body
        className="d-flex flex-column align-items-center justify-content-between"
        style={{
          background: "#F7E7D8", // Background color inside the card
          borderRadius: "20px", // Ensure corners are rounded
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
          border: "10px solid", // Border size for the gradient effect
          borderImage: "linear-gradient(to bottom right, #A8ADE2, #EEB87B) 1", // Gradient border
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: "10px", // Padding inside the card to give some space around the text
        }}
      >
        {/* Badge Globe Image */}
        <div className="text-center">
          <img
            src={BadgeGlobe}
            alt="Badge Globe"
            style={{
              width: "150px",
              height: "auto",
              marginBottom: "20px",
            }}
          />
        </div>

        {/* Name and Subtitle */}
        {/* <div className="text-center">
        <h1
          style={{
            fontSize: "40px",
            fontWeight: "bold",
            color: "#2E3A59",
            margin: "0 0 10px 0",
          }}
        >
          {name}
        </h1>
      </div> */}

        {/* Footer with NetNada Logo */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // alignItems: "center",
            }}
          >
            <h1
              style={{
                fontSize: "40px",
                fontWeight: "bold",
                color: "#2E3A59",
                margin: "0 0 0px 0",
              }}
            >
              {name}
            </h1>
            <p
              style={{
                fontSize: "14px",
                // fontWeight: "bold",
                color: "#2E3A59",
                margin: "0",
              }}
            >
              FIXING CLIMATE CHANGE WHILE{" "}
              <span style={{ fontWeight: "bold" }}>LISTENING TO ABBA</span>
            </p>
          </div>
          <div
            style={{
              borderRadius: "50%",
              margin: "80px 0 0 20px",
            }}
          >
            {/* <br /> <br /> */}
            <img
              src="https://cdn.filestackcontent.com/jI0Bz5GXRTmfkMONEOJH"
              alt="NetNada Logo"
              style={{ width: "100px", height: "auto" }}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

const BadgeDemo = () => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
      <ClimateChampionBadge
        name="CARLOS"
        subtitle="FIXING CLIMATE CHANGE WHILE LISTENING TO ABBA"
        // year="2024"
      />
    </div>
  );
};

export default BadgeDemo;
