import React, { useState } from "react";
import { Card, Form, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";

const Onboarding = ({ currentQuestion, answers, handleAnswer }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [otherSoftware, setOtherSoftware] = useState(""); // State for storing "Other" software input
  const [showOtherInput, setShowOtherInput] = useState(false); // State for showing the "Other" input
  const [selectedIntegration, setSelectedIntegration] = useState(null);

  // Handle selecting multiple options
  const handleSelectOption = (optionLabel) => {
    if (selectedOptions.includes(optionLabel)) {
      // If the option is already selected, deselect it
      setSelectedOptions((prevSelected) =>
        prevSelected.filter((label) => label !== optionLabel)
      );
    } else {
      // If not selected, add it to the selected options array
      setSelectedOptions((prevSelected) => [...prevSelected, optionLabel]);
    }
    handleAnswer(optionLabel); // Pass the answer up to parent
  };

  const handleSelect = (size) => {
    setSelectedSize(size);
  };

  const handleChange = (option) => {
    setSelectedOption(option); // Update the selected option in the state
  };

  const cardStyle = {
    cursor: "pointer",
    border: "1px solid #ddd",
    borderRadius: "8px",
    transition: "border 0.3s ease",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "70px", // Ensuring a fixed height for all cards
    width: "100%", // Use full width of the container
    maxWidth: "350px", // Set a max width for each card
    margin: "0 auto", // Center the card
  };

  switch (currentQuestion.type) {
    case "SelectBox":
      return (
        <Form.Group className="mb-3">
          {currentQuestion.options.map((option, index) => (
            <Card
              key={index}
              className="mb-2 p-3"
              onClick={() => handleAnswer(option.label, currentQuestion.id)}
              style={{
                cursor: "pointer",
                width: "600px", // Use full width
                height: "60px", // Fixed height
                boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                transition: "all 0.3s ease",
                backgroundColor: "#fff",
                border:
                  answers[currentQuestion.id] === option.label
                    ? "2px solid #FFD4A9" // Selection border color
                    : "1px solid #ddd", // Default border color
              }}
            >
              <Card.Body
                className="d-flex align-items-center justify-content-between"
                style={{ padding: "1rem", height: "100%", width: "100%" }}
              >
                <div className="d-flex align-items-center">
                  {/* <FontAwesomeIcon
                    icon={option.icon}
                    style={{
                      backgroundColor: option.color,
                      borderRadius: "50%",
                      padding: "8px",
                      marginRight: "10px",
                    }}
                  /> */}
                  <div
                    style={{
                      // fontSize: "30px",
                      backgroundColor: option.color,
                      borderRadius: "50%",
                      padding: "8px",
                      marginRight: "10px",
                    }}
                  >
                    {option.icon}
                  </div>
                  <span style={{ fontSize: "18px" }}>{option.label}</span>
                </div>
                {answers[currentQuestion.id] === option.label ? (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    // color="#FFD4A9"
                    color="#005677"
                    // className="text-primary"
                  />
                ) : (
                  <div style={{ width: "24px" }}></div>
                )}
              </Card.Body>
            </Card>
          ))}
        </Form.Group>
      );

    case "TextBox":
      return (
        <div className="container">
          {currentQuestion.options.map((option, index) => (
            <div key={index} style={{ width: "600px", marginBottom: "10px" }}>
              <Card>
                <Card.Body className="p-3">
                  <Form.Group>
                    <Form.Control
                      type="text"
                      value={answers[currentQuestion.id]?.[option.label] || ""}
                      onChange={(e) =>
                        handleAnswer(
                          { [option.label]: e.target.value },
                          currentQuestion.id
                        )
                      }
                      placeholder={option.label}
                    />
                  </Form.Group>
                </Card.Body>
              </Card>
            </div>
          ))}
        </div>
      );

    case "MultiSelectBox":
      return (
        <>
          {currentQuestion.options.map((option, index) => {
            const selectedOptions = answers[currentQuestion.id] || []; // Get selected options for this question
            const isSelected = selectedOptions.includes(option.label); // Check if this option is selected

            return (
              <Form.Group className="mb-3" key={index}>
                <Card
                  onClick={() => handleAnswer(option.label, currentQuestion.id)} // Toggle selection
                  style={{
                    cursor: "pointer",
                    width: "100%", // Use full width
                    height: "60px", // Fixed height
                    boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                    transition: "all 0.3s ease",
                    backgroundColor: "#fff",
                    border: isSelected ? "2px solid #007bff" : "1px solid #ddd", // Border change on selection
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between", // Align content horizontally
                    padding: "0 15px", // Ensure consistent padding
                  }}
                >
                  <Card.Body
                    className="d-flex align-items-center justify-content-between"
                    style={{ padding: "1rem", height: "100%", width: "100%" }}
                  >
                    <div className="d-flex align-items-center">
                      <div
                        style={{
                          backgroundColor: option.color,
                          borderRadius: "50%",
                          padding: "8px",
                          marginRight: "10px",
                        }}
                      >
                        {option.icon}
                      </div>
                      <span style={{ fontSize: "18px" }}>{option.label}</span>
                    </div>
                    {isSelected && (
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="text-primary"
                      />
                    )}
                  </Card.Body>
                </Card>
              </Form.Group>
            );
          })}
        </>
      );

    case "IntegrationSelectBox":
      return (
        <div>
          <Row className="justify-content-center">
            {currentQuestion.options
              .filter((integration) => integration.label !== "Other")
              .map((integration) => {
                const isSelected =
                  answers[currentQuestion.id] === integration.label;

                return (
                  <Col
                    xs={6}
                    md={4}
                    className="mb-4 d-flex justify-content-center"
                    key={integration.label}
                  >
                    <Card
                      className="p-3"
                      style={{
                        cursor: "pointer",
                        borderRadius: "16px",
                        backgroundColor: isSelected
                          ? integration.color + "1A"
                          : "#F1F1F1",
                        border: isSelected
                          ? `2px solid ${integration.color}`
                          : "2px solid transparent",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "150px",
                        width: "150px",
                        transition:
                          "border 0.3s ease, background-color 0.3s ease",
                        position: "relative",
                      }}
                      onClick={() => {
                        setShowOtherInput(false);
                        handleAnswer(integration.label, currentQuestion.id);
                      }}
                    >
                      <div style={{ textAlign: "center" }}>
                        <img
                          src={integration.logo}
                          alt={integration.label}
                          style={{
                            width: integration.size,
                            height: integration.size,
                            objectFit: "contain",
                            marginBottom: "10px",
                          }}
                        />
                        {isSelected && (
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            style={{
                              color: integration.color,
                              fontSize: "20px",
                              position: "absolute",
                              top: "10px",
                              right: "10px",
                            }}
                          />
                        )}
                      </div>
                    </Card>
                  </Col>
                );
              })}

            {/* Render the "Other" option, spanning across the whole row when selected */}
            <Col
              xs={12}
              className="mb-4"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Card
                className="p-3"
                style={{
                  cursor: "pointer",
                  borderRadius: "16px",
                  backgroundColor: showOtherInput
                    ? "#0000001A" // Black background with transparency when "Other" is selected
                    : "#F1F1F1", // Default background color
                  border: showOtherInput
                    ? "2px solid black" // Black border when "Other" is selected
                    : "2px solid transparent", // Transparent border when not selected
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "150px",
                  width: showOtherInput ? "100%" : "150px", // Full width if expanded
                  transition: "border 0.3s ease, background-color 0.3s ease",
                  position: "relative",
                }}
                onClick={() => {
                  setShowOtherInput(true);
                  handleAnswer(otherSoftware, currentQuestion.id);
                }}
              >
                <div style={{ textAlign: "center", width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {!showOtherInput ? (
                      <div
                        style={{
                          backgroundColor: "#F1F1F1",
                          borderRadius: "50%",
                          padding: "15px",
                          marginBottom: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPlusCircle}
                          style={{
                            color: "black",
                            fontSize: "20px",
                          }}
                        />
                      </div>
                    ) : (
                      // <div
                      //   style={{
                      //     backgroundColor: "#F1F1F1",
                      //     borderRadius: "50%",
                      //     padding: "15px",
                      //     marginBottom: "10px",
                      //     display: "flex",
                      //     justifyContent: "center",
                      //     alignItems: "center",
                      //   }}
                      // >
                      //  <CheckCircle size={30} />
                      //  </div>
                      // {/* <FontAwesomeIcon
                      //   icon={faCheckCircle}
                      //   style={{
                      //     color: "black",
                      //     fontSize: "20px",
                      //     // marginRight: "5px",
                      //   }}
                      // /> */}

                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        style={{
                          color: "black",
                          fontSize: "20px",
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                        }}
                      />
                    )}
                    <p
                      style={{
                        margin: "0",
                        fontSize: showOtherInput ? "18px" : "16px",
                        fontWeight: "500",
                      }}
                    >
                      Other
                    </p>
                  </div>

                  {showOtherInput && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "15px",
                      }}
                    >
                      <Form.Group controlId="otherSoftwareInput">
                        <Form.Control
                          type="text"
                          placeholder="Enter software name"
                          value={otherSoftware}
                          onChange={(e) => setOtherSoftware(e.target.value)}
                          style={{
                            width: "300px", // Set the width of the input
                            transition: "width 0.3s ease",
                          }}
                        />
                      </Form.Group>
                    </div>
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      );

    case "MultiSelectDecisionBox":
      return (
        <Form.Group className="mb-3">
          {currentQuestion.options.map((option, index) => (
            <Card
              key={index}
              className="mb-2 p-3"
              onClick={() => handleAnswer(option.label, currentQuestion.id)}
              style={{
                cursor: "pointer",
                width: "600px", // Use full width
                height: "60px", // Fixed height
                boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                transition: "all 0.3s ease",
                backgroundColor: "#fff",
                border:
                  // Check if the option is part of the selected answers array
                  (answers[currentQuestion.id] || []).includes(option.label)
                    ? "2px solid #FFD4A9" // Selection border color
                    : "1px solid #ddd", // Default border color
              }}
            >
              <Card.Body
                className="d-flex align-items-center justify-content-between"
                style={{ padding: "1rem", height: "100%", width: "100%" }}
              >
                <div className="d-flex align-items-center">
                  <div
                    style={{
                      backgroundColor: option.color,
                      borderRadius: "50%",
                      padding: "8px",
                      marginRight: "10px",
                    }}
                  >
                    {option.icon}
                  </div>
                  <span style={{ fontSize: "18px" }}>{option.label}</span>
                </div>

                {/* Checkmark for selected options */}
                {(answers[currentQuestion.id] || []).includes(option.label) ? (
                  <FontAwesomeIcon icon={faCheckCircle} color="#005677" />
                ) : (
                  <div style={{ width: "24px" }}></div>
                )}
              </Card.Body>
            </Card>
          ))}
        </Form.Group>
      );

    default:
      return null;
  }
};

export default Onboarding;
