import React, { useEffect, useState } from "react";
import OnBoardingCardLayout from "../../layouts/OnBoardingCardLayout";
import Onboarding from "./Onboarding";
import { questionsData } from "../../data/OnBoardingData";
import GettingStarted from "./GettingStarted";
import {
  saveOnBoardingDetails,
  updateSuppliersCollection,
} from "./OnBoardingAPI";
import {
  getActiveUserData,
  getWhiteLabel,
  setActiveUserData,
} from "../../helpers/store";
import { createContentImageAPI } from "../content/contentApi";

const LandingPage = () => {
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  // Combine all state in a single object for better management
  const [state, setState] = useState({
    showQuestions: false,
    currentQuestionIndex: 0,
    isLastQuestion: false,
    userName: activeUser.displayName,
    answers: {},
    showGettingStarted: false,
  });

  const [isSkipped, setIsSkipped] = useState(false);
  const [questions, setQuestions] = useState(questionsData);

  // Toggle the visibility of the question panel
  const handleShowQuestions = () => {
    setState((prevState) => ({
      ...prevState,
      showQuestions: !prevState.showQuestions,
    }));
  };

  // Function to build the final onboarding details object
  const buildDetails = (answers) => ({
    CAExperience: answers[1] || null,
    CompletingReportFor: answers[2] || null,
    NameOfTheCompany: answers[3] || null,
    NumberOfEmployees: answers[4] || null,
    Category: answers[5] || null,
    Integration: answers[6] || null,
    CAReason: answers[7] || null,
    CARequestingCompanyDetails: answers[8]
      ? {
          companyName: answers[8]?.["Company Name"] || null, // Save null if empty
          companyWebsite: answers[8]?.["Website"] || null, // Save null if empty
        }
      : null, // If answers[8] doesn't exist, save as null
    CARequestingTenderDetails: answers[9]
      ? {
          tenderName: answers[9]?.["Tender Name"] || null, // Save null if empty
          tenderWebsite: answers[9]?.["Website"] || null, // Save null if empty
        }
      : null, // If answers[9] doesn't exist, save as null
    CAReporting: answers[10] || null, // Save null if empty
  });

  // Function to handle getting the next question
  const getNextQuestion = (currentQuestion, answer) => {
    // For MultiSelectDecisionBox
    if (currentQuestion.id === 7 && Array.isArray(answer)) {
      const selectedNextQuestions = currentQuestion.options
        .filter((option) => answer.includes(option.label)) // Filter the selected options
        .map((option) => option.nextQuestion) // Map to their nextQuestion IDs
        .filter((nextQuestionId) => nextQuestionId !== null); // Ignore nulls

      // If there are multiple selected options, return the first follow-up question to display
      return selectedNextQuestions.length > 0 ? selectedNextQuestions[0] : null;
    }

    // For other types like DecisionSelectBox or SelectBox
    // if (
    //   currentQuestion.type === "DecisionSelectBox" &&
    //   currentQuestion.options
    // ) {
    //   const selectedOption = currentQuestion.options.find(
    //     (opt) => opt.label === answer
    //   );
    //   return selectedOption?.nextQuestion ?? null;
    // }

    return currentQuestion.nextQuestion;
  };

  // Function to handle the previous question logic
  const getPreviousQuestion = (currentQuestion) => {
    return currentQuestion.previousQuestion;
  };

  // Function to handle answers without advancing to the next question
  const handleAnswer = (answer, questionId) => {
    setState((prevState) => {
      const currentQuestion = questions.find((q) => q.id === questionId);
      const updatedAnswers = { ...prevState.answers };

      // Handle MultiSelectBox answers
      if (currentQuestion.type === "MultiSelectBox") {
        const selectedOptions = updatedAnswers[questionId] || [];
        const updatedOptions = selectedOptions.includes(answer)
          ? selectedOptions.filter((opt) => opt !== answer)
          : [...selectedOptions, answer];
        updatedAnswers[questionId] = updatedOptions;
      }

      //Handle MultiSelectDecisionBox answers
      else if (
        currentQuestion.type === "MultiSelectDecisionBox" &&
        questionId === 7
      ) {
        const selectedOptions = updatedAnswers[questionId] || [];
        const updatedOptions = selectedOptions.includes(answer)
          ? selectedOptions.filter((opt) => opt !== answer)
          : [...selectedOptions, answer];
        updatedAnswers[questionId] = updatedOptions;

        // Dynamically update the nextQuestion field based on selected options
        const selectedNextQuestions = currentQuestion.options
          .filter((option) => updatedOptions.includes(option.label))
          .map((option) => option.nextQuestion)
          .filter((nextQuestionId) => nextQuestionId !== null)
          .sort((a, b) => a - b); // Sort the selected next questions in ascending order

        // Update the questions array with the nextQuestion field set to the first selected option
        if (selectedNextQuestions.length > 0) {
          // Update question 7's nextQuestion to the first valid next question
          setQuestions((prevState) => {
            const updatedQuestions = [...prevState];
            updatedQuestions[
              updatedQuestions.findIndex((q) => q.id === questionId)
            ].nextQuestion = selectedNextQuestions[0];
            return updatedQuestions;
          });

          // If multiple options are selected, set their nextQuestion to null except the first

          for (let i = 0; i < selectedNextQuestions.length - 1; i++) {
            const currentQuestionIndex = questions.findIndex(
              (q) => q.id === selectedNextQuestions[i]
            );
            const nextQuestionIndex = questions.findIndex(
              (q) => q.id === selectedNextQuestions[i + 1]
            );

            // Update the nextQuestion and previousQuestion fields for the selected options
            setQuestions((prevState) => {
              const updatedQuestions = [...prevState];
              updatedQuestions[currentQuestionIndex].nextQuestion =
                selectedNextQuestions[i + 1];
              updatedQuestions[nextQuestionIndex].previousQuestion =
                selectedNextQuestions[i];
              return updatedQuestions;
            });
          }
        } else {
          // If no valid nextQuestion, set it to null (i.e., no further questions)
          questions[
            questions.findIndex((q) => q.id === questionId)
          ].nextQuestion = null;
        }
      }

      // Handle TextBox answers
      else if (currentQuestion.type === "TextBox") {
        const previousAnswers = updatedAnswers[questionId] || {};
        updatedAnswers[questionId] = { ...previousAnswers, ...answer };
      }

      // For SelectBox and other types, store a single answer
      else {
        updatedAnswers[questionId] = answer;
      }

      // Update the state with the updated answers
      return { ...prevState, answers: updatedAnswers };
    });
  };

  // Handle moving to the next question when clicking "Next"
  const handleNext = () => {
    const currentQuestion = questions[state.currentQuestionIndex];
    const answer = state.answers[currentQuestion.id];

    if (currentQuestion.id === 7 && Array.isArray(answer)) {
      // Handle multiple selected options for question 7
      const selectedNextQuestions = currentQuestion.options
        .filter((option) => answer.includes(option.label))
        .map((option) => option.nextQuestion)
        .filter((nextQuestionId) => nextQuestionId !== null);

      if (selectedNextQuestions.length > 0) {
        // Move to the next question based on dynamic selection
        setState((prevState) => ({
          ...prevState,
          currentQuestionIndex: questions.findIndex(
            (q) => q.id === selectedNextQuestions[0]
          ),
          isLastQuestion: false,
        }));
      } else {
        // If no more questions, finish onboarding
        const details = buildDetails(state.answers);
        saveOnBoardingDetails(details);

        //update user onboarding status
        activeUser.onboardingCompleted = true;
        setActiveUserData(JSON.stringify(activeUser));

        //update the state to show the getting started screen
        setState((prevState) => ({
          ...prevState,
          showQuestions: false,
          showGettingStarted: true,
        }));
      }
    } else {
      // For all other questions, move to the next question as normal
      const nextQuestionId = getNextQuestion(currentQuestion, answer);

      if (nextQuestionId) {
        setState((prevState) => ({
          ...prevState,
          currentQuestionIndex: questions.findIndex(
            (q) => q.id === nextQuestionId
          ),
          isLastQuestion: false,
        }));
      } else {
        // Finish the onboarding if there's no next question
        const details = buildDetails(state.answers);
        saveOnBoardingDetails(details);

        //update user onboarding status
        activeUser.onboardingCompleted = true;
        setActiveUserData(JSON.stringify(activeUser));

        // Show the getting started screen
        setState((prevState) => ({
          ...prevState,
          showQuestions: false,
          showGettingStarted: true,
        }));
      }
    }
  };

  // Handle moving to the previous question
  const handleBack = () => {
    const currentQuestion = questions[state.currentQuestionIndex];
    const previousQuestionId = getPreviousQuestion(currentQuestion);

    if (previousQuestionId) {
      setState((prevState) => ({
        ...prevState,
        currentQuestionIndex: questions.findIndex(
          (q) => q.id === previousQuestionId
        ),
      }));

      // Delete the answer for the current question
      setState((prevState) => {
        const updatedAnswers = { ...prevState.answers };
        delete updatedAnswers[currentQuestion.id];
        return { ...prevState, answers: updatedAnswers };
      });
    }
  };

  // Function to create a content image
  async function createContentImage(userData) {
    const body = {
      requestedBy: userData.uid,
      contentType: "Milestone",
      title: `Welcome to ${getWhiteLabel().name}`,
      description: `Welcome to ${getWhiteLabel().name}`,
      imageName: "welcome.jpg",
      modifications: {
        companyName: userData.businessName,
        whitelabelName: getWhiteLabel().name,
      },
    };
    const response = await createContentImageAPI(body);
  }

  // Function to finish the onboarding process
  const finishOnboarding = async () => {
    let userData = JSON.parse(getActiveUserData());

    userData["businessName"] = userData.organisation;
    setActiveUserData(JSON.stringify(userData));

    const body = {
      businessId: userData["businessId"],
      businessName: userData.organisation,
    };

    await updateSuppliersCollection(body);
    await createContentImage(userData);

    setState((prevState) => ({
      ...prevState,
      showQuestions: false,
      showGettingStarted: true,
    }));
  };

  // Handle skipping a question
  const handleSkip = () => {
    const currentQuestion = questions[state.currentQuestionIndex];

    // Reset the answer for the current question
    setState((prevState) => {
      const updatedAnswers = { ...prevState.answers };
      delete updatedAnswers[currentQuestion.id]; // Remove the answer of the current question
      // Set the updated state with the cleared answer
      return { ...prevState, answers: updatedAnswers };
    });

    //Set the isSkipped state to true
    setIsSkipped(true);

    // Update the state with the cleared answer and call handleNext after state update
    // handleNext(); // Ensure handleNext is called after the state is updated
  };

  // Call handleNext after the state is updated
  useEffect(() => {
    if (isSkipped) {
      handleNext();
      setIsSkipped(false);
    }
  }, [isSkipped]);

  // Function to check if the user is on the last question when answer is null
  const checkIfLastQuestion = (currentQuestion) => {
    const type = currentQuestion.type;

    if (type === "TextBox") {
      const nextQuestionId = currentQuestion.nextQuestion;
      if (nextQuestionId === null) {
        setState((prevState) => ({
          ...prevState,
          isLastQuestion: true,
        }));
      }
    } else {
      const nextQuestion = questions[state.currentQuestionIndex + 1] || null;

      // If no next question and we are not on the last question, it's the end of the onboarding
      if (nextQuestion === null) {
        setState((prevState) => ({
          ...prevState,
          isLastQuestion: true,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          isLastQuestion: false,
        }));
      }
    }
  };

  // Check if it’s the last question
  useEffect(() => {
    const currentQuestion = questions[state.currentQuestionIndex];
    const currentQuestionAnswer = state.answers[currentQuestion?.id] || null;

    if (currentQuestionAnswer !== null) {
      const nextQuestion = getNextQuestion(
        currentQuestion,
        currentQuestionAnswer
      );
      setState((prevState) => ({
        ...prevState,
        isLastQuestion: !nextQuestion,
      }));
    } else {
      checkIfLastQuestion(currentQuestion);
    }
  }, [state.currentQuestionIndex, state.answers]);

  // Safely access the current question
  const currentQuestion =
    questions && questions.length > 0
      ? questions[state.currentQuestionIndex]
      : null;

  let isAnswerSelected = false; // Initialize it as false

  // Check for type 'TextBox' with multiple keys (e.g., { Tender Name: 'ddd', Website: 'ki' })
  if (currentQuestion?.type === "TextBox") {
    const answer = state.answers[currentQuestion?.id];

    // If answer exists and it's an object, check if all keys have values
    if (answer && typeof answer === "object") {
      // Check if every key in the answer object has a non-empty value
      isAnswerSelected = Object.keys(answer).every(
        (key) => answer[key] !== undefined && answer[key] !== "" // Ensure no empty values
      );
    }
  } else {
    // Default check for other types (like SelectBox, etc.)
    isAnswerSelected = state.answers[currentQuestion?.id] !== undefined;
  }

  return !state.showGettingStarted ? (
    <OnBoardingCardLayout
      leftSideContent={
        <div>
          <h2>
            <span style={{ color: "#005677" }}>{currentQuestion.question}</span>
          </h2>
          <p className="text-muted">{currentQuestion.description}</p>
        </div>
      }
      showQuestions={state.showQuestions}
      showBack={state.currentQuestionIndex > 0}
      skip={currentQuestion.skip}
      handleQuestions={handleShowQuestions}
      handleBack={handleBack}
      handleNext={handleNext}
      handleSkip={handleSkip}
      isLastQuestion={state.isLastQuestion}
      userName={state.userName}
      tooltipText={currentQuestion.tooltipText}
      isAnswerSelected={isAnswerSelected}
    >
      <Onboarding
        currentQuestion={currentQuestion}
        answers={state.answers}
        handleAnswer={handleAnswer}
        currentQuestionIndex={state.currentQuestionIndex}
      />
    </OnBoardingCardLayout>
  ) : (
    <GettingStarted />
  );
};

export default LandingPage;
